<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1 pb-5" data-app>
        <h1>Benutzerverwaltung</h1>
        <!--begin::Info-->
        <div class="d-flex align-items-center pr-2 mb-6">
          <span class="text-muted font-weight-bold font-size-lg flex-grow-1"
            >Benutzer erstellen, löschen oder editieren</span
          >

          <div class="symbol symbol-50">
            <span class="symbol-label bg-light-light">
              <img
                src="/media/svg/icons/General/User.svg"
                class="h-50 align-self-center"
                alt=""
              />
            </span>
          </div>
        </div>
        <!--end::Info-->

        <TableCrud :users="usersClone"
                   :loadingVariable="loadingVariable">
        </TableCrud>
      </div>
    </div>
  </div>
</template>

<script>
import TableCrud from "@/view/content/table/TableCrud";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {LOGOUT} from "@/core/services/store/auth.module";

export default {
  name: "Usermanagement",
  components: { TableCrud },
  props: {
    users: {
      type: []
    }
  },
  data() {
    return {
      loadingVariable: false,
      usersClone: this.users
    };
  },
  created() {
    this.loadingVariable = true;
    ApiService.query("users/allusers")
      .then(({ data }) => {
        this.usersClone = data;
        this.loadingVariable = false;
      })
      .catch(({ response }) => {
        if (response.status === 401) {
          this.$store
            .dispatch(LOGOUT)
            .then(() => this.$router.push({ name: "login" }));
        }
      });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Settings" }]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>

<style scoped></style>
